import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`MASTERCARD (DRI):  EFFECTIVE DATE 12TH APRIL 2019`}</strong></p>
    <p>{`MC (DRI) Is a dispute process that was put in place on 12th April 2019. The system is to enhance the capability to prevent invalid disputes from entering the MasterCard Network. Those disputes deemed valid by MC will allow for the Issuer to file a dispute on behalf of their cardholder.  A merchant may contest a chargeback via a 2nd presentment or accept the dispute. All cases that receive a response will be assessed a MC association system fee of $3.00 per case.  The fee will commence on any August 2019 case.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      